import axios from "axios";
import store from "../store";
import VueCookies from 'vue-cookies'
import qs from "qs";

let config = {
    // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
    baseURL: "",
    // 请求超时时间
    timeout: 300000,
    // 每次请求携带cookie
    withCredentials: true,
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType: 'json'
};

const instance = axios.create(config);

// http request 拦截器
instance.interceptors.request.use(
    config => {
        const token = VueCookies.get('access_token');
        if (token) { //判断token是否存在
            config.headers['Authorization'] = 'Bearer '+token;  //将token设置成请求头
        }
        config.headers["X-Requested-With"] = "XMLHttpRequest";
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// 自定义接口函数
instance.api = {
    // 用户信息
    userinfo: function (params, callback) {
        instance.get("/api/v2/member/detail?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 首页
    home: function (params, callback) {
        instance.get("/api/v2/index/home?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 首页-链接从www后台获取
    edulink: function (params, callback) {
        instance.get("/api/www/edu/edulink?"+ qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 课程列表初始化
    courseListInit: function (params, callback) {
        instance.get("/api/v2/index/courseListInit?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 课程列表
    courseList: function (params, callback) {
        instance.get("/api/v2/index/courseList?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 课程详情
    courseDetail: function (params, callback) {
        instance.get("/api/v2/index/courseDetail?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 视频详情
    videoDetail: function (params, callback) {
        instance.get("/api/v2/index/videoDetail?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 视频播放Url
    videoPlayUrl: function (params, callback) {
        instance.get("/api/v2/index/videoPlayUrl?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 课程收藏
    courseLike: function (id, callback) {
        instance.get("/api/v2/course/"+id+"/like").then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 搜索
    search: function (params, callback) {
        instance.post("/api/v2/index/search", params).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 相关推荐
    recommend: function (params, callback) {
        instance.post("/api/v2/index/recommend", params).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 下载-素材
    downMaterial: function (params, callback) {
        instance.get("/api/v2/down/material?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 下载-软件
    downSoftware: function (params, callback) {
        instance.get("/api/v2/down/software?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 下载-acr
    downAcr: function (params, callback) {
        instance.get("/api/v2/down/acr?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 下载-帮助
    downHelp: function (params, callback) {
        instance.get("/api/v2/down/help?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 下载-详情
    downDetail: function (params, callback) {
        instance.get("/api/v2/down/detail?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 学习专区-地图数据
    zqMap: function (params, callback) {
        instance.get("/api/www/edu/zqMap?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 学习专区-区域数据
    zqList: function (params, callback) {
        instance.get("/api/www/edu/zqList?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 学习专区-我的专区
    myZq: function (params, callback) {
        instance.get("/api/www/edu/myZq?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // 活动页
    activity: function (params, callback) {
        instance.get("/api/www/edu/activity?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
    // acr会免支付
    acrFreeGive: function (params, callback) {
        instance.get("/api/www/edu/acrFreeGive?" + qs.stringify(params)).then((res) => {
            callback(res.data);
        }).catch((error) => {
            callback({"code": 0});
        });
    },
}

export default instance